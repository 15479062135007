import React, {useEffect, useState} from 'react';
import withHeader from '../hoc/withHeader';
import {deleteUsersById, fetchUsers} from "../../services/panel.service";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

const UserPage = () => {
  const userSel = useSelector(state => state.user.user);
  const [userData, setUserData] = useState([]);
  const [userDataTmp, setUserDataTmp ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (userSel.role.label === 'ca') {
      history.push('/panel/dashboard-ca');
    }
    usersData();
  }, [userSel, history]);

  const usersData = () => {
    setLoading(true);
    fetchUsers().then(res => {
      setUserDataTmp(res.data.data);
      setUserData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const handleChange = (event) => {
    setSearch(event.target.value)
    let searchRes = userDataTmp;

    if (!event.target.value) {
      setUserData(userDataTmp);
      return;
    }

    const searchRes2 = searchRes.filter((val) => {
      return val.full_name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
          val.email.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
          val.phone_number.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
    });
    setUserData(searchRes2);
  }

  const deleteHandler = (id) => {
    // console.log(id);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to delete this data?");
    if (!r) {
      return;
    }

    deleteUsersById(id, {}).then(res => {
      console.log(res);
      alert('delete data success');
      usersData();
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      setLoading(false);
    });
  }

  const createNewUserHandler = () => {
    console.log('new');
    history.push('/panel/users-new');
  }

  const updateUserHandler = (id) => {
    console.log('new');
    history.push('users-edit/' + id);
  }

  const loopUser = () => {

    return userData.map((val, i) => {
      let role = {};
      if (val.role) {
        role = JSON.parse(val.role);
      } else {
        role.label = '-';
      }
      return (
          <tr key={i}>
            <td>{ val._id }</td>
            <td>{ val.full_name }</td>
            <td>{ val.email }</td>
            <td>{ val.phone_number }</td>
            {/*<td><span className="tag tag-success">Approved</span></td>*/}
            <td>{ role.label }</td>
            <td>{ val.bank_code }</td>
            <td>
              <span className="badge bg-danger mr-1" onClick={() => { deleteHandler(val._id)}}>
                <i className="fas fa-trash-alt p-1" aria-hidden="true" />
              </span>

              <span className="badge bg-success mr-1" onClick={() => {updateUserHandler(val._id)}}>
                <i className="fas fa-pencil-alt p-1" aria-hidden="true" />
              </span>
            </td>
          </tr>
      );
    })
  }

  return (
      <div className="row">
        <div className="col-8">
          <input
              type="text"
              name="table_search"
              className="form-control float-right p-1"
              value={search}
              onChange={handleChange}
              placeholder="Search" />
        </div>
        <div className="col-4">
          <button type="button" className="btn btn-block btn-primary" onClick={() => {createNewUserHandler()}}>
            <i className='fas fa-plus' aria-hidden='true' />
          </button>
        </div>
        <div className="col-12">
          <hr className='hr-user' />
        </div>
        <div className="col-12">
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Bank Code</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              { loading && <tr><td colSpan='6'>Load Data</td></tr> }
              {/*{ (!loading && userData.length > 0) && userData }*/}
              { (!loading && userData.length > 0) && loopUser() }
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}

let pageSet = {
  title: "User Management",
  location: '/panel/users'
}

export default withHeader(pageSet)(UserPage)
