import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {fetchUsersById, updateUser} from "../../../services/panel.service";
import {useHistory, useParams} from "react-router";
import {roles} from "../../../assets/static/data";
import Select from "react-select";
import { fetchMerchants } from '../../../services/merchant.service';

// import {fetchUsers} from "../../../services/panel.service";

const UserEditPage = () => {
  const [reqParams, setReqParams] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    identity_id: '',
    active: 1,
    password: '',
    role: '',
    bank_code: '',
  });
  const [confirmPass, setConfirmPass] = useState('');
  let { id } = useParams();
  const history = useHistory();
  const [merchantData, setMerchantData] = useState([]);
  useEffect((idParams = id) => {
    usersData(idParams);
    merchants();
  }, [id]);

  const usersData = (id) => {
    fetchUsersById(id).then(res => {
      // console.log('res', res.data)
      let data = res.data.data;
      data.full_name = res.data.data.full_name ? res.data.data.full_name : '';
      data.phone_number = res.data.data.phone_number ? res.data.data.phone_number : '';
      data.identity_id = res.data.data.identity_id ? res.data.data.identity_id : '';
      data.role = res.data.data.role ? JSON.parse(res.data.data.role) : '';
      data.bank_code = res.data.data.bank_code ? res.data.data.bank_code : '';
      data.password = '';
      delete data._id;
      delete data.created_at;
      delete data.created_by;
      delete data.updated_at;
      delete data.updated_by;
      delete data.userData;
      delete data.__v;
      delete data.userData;
      setReqParams(data)
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally( () => {
      console.log('finish load data')
    });
  }
  const merchants = () => {
    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }
  
  const handleChange = (event) => {
    const params = {...reqParams}
    params[event.target.name] = event.target.value;
    setReqParams(params);
  }

  const handleChange2 = (event) => {
    const params = {...reqParams}
    params.role = event;
    setReqParams(params);
    console.log(params);
  }
  const handleChangeBankCode = (event) => {
    const params = {...reqParams}
    params.bank_code = event.value;
    setReqParams(params);
    console.log(params);
  }
  const handleConfirmPassChange = (event) => {
    setConfirmPass(event.target.value);
  }

  const selectRole = (val) => {
    const options = roles().map(val => {
      return {
        value: val.id,
        label: val.name
      }
    });

    if (typeof val === "string") {
      val = JSON.parse(val);
    }

    return (
        <Select
            value={options.value}
            options={options}
            placeholder='select role'
            defaultValue={val}
            onChange={handleChange2}
        />
    );
  }

  const handleUpdateUser = async (event) => {
    event.preventDefault();
    if (confirmPass !== reqParams.password) {
      alert('password should same with confirm password!');
      return;
    }

    try {
      let result = await updateUser(id, reqParams);
      console.log(result);
      if (result.status === 200 && result.data.message === 'updated') {
        alert('Success Update User');
        history.push('/panel/users');
      }
    } catch (e) {
      console.log('error', e.response);
      alert(e.response.data.message);
    }
  }
  const BankOptions = merchantData.map(value => {
    return {value: value.kode, label: value.name, name: 'bank_code', key:value._id}
  })
  const BanksSelectComponent = () => (
    <Select
        {...BankOptions}
        options={BankOptions}
        placeholder='select CA'
        value={
          BankOptions.filter(option =>
              option.value === reqParams.bank_code)
        }
        id="bank_code"
        name='bank_code'
        onChange={handleChangeBankCode}
        required
    />
)
  const handleBack = () => {
    // console.log(123);
    history.push('/panel/users');
  }

  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleUpdateUser}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    placeholder="Enter full name"
                    value={reqParams.full_name}
                    name='full_name'
                    onChange={handleChange}
                    required />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name='email'
                    value={reqParams.email}
                    onChange={handleChange}
                    placeholder="Enter email"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name='phone_number'
                    value={reqParams.phone_number}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="identity">Identity ID</label>
                <input
                    type="text"
                    className="form-control"
                    id="identity"
                    name='identity_id'
                    placeholder="Enter identity"
                    value={reqParams.identity_id}
                    onChange={handleChange}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="identity">Role</label>
                {reqParams.role && selectRole(reqParams.role)}
              </div>
              <div className="form-group">
                <label htmlFor="bank_code">Bank Code</label>
                {/* <input
                    type="text"
                    className="form-control"
                    id="bank_code"
                    name='bank_code'
                    placeholder="Enter bank_code"
                    value={reqParams.bank_code}
                    onChange={handleChange}
                    required
                /> */}
                {BanksSelectComponent()}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    name='password'
                    placeholder="Password"
                    value={reqParams.password}
                    onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPass}
                    onChange={handleConfirmPassChange}
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                  type="button"
                  className="btn btn-danger float-left"
                  onClick={() => handleBack()}
              >
                Back
              </button>

              <button type="submit" className="btn btn-primary float-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Update Users",
  location: '/panel/users-edit/id'
}

export default withHeader(pageSet)(UserEditPage)
