import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
// import {useHistory} from "react-router";
import {fetchTrx} from "../../../services/trx.service";
import moment from 'moment';
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {status} from "../../../assets/static/data";
import {API_DSN} from "../../../services/config";
import Select from "react-select";
import {fetchMerchants} from "../../../services/merchant.service";
import {fetchBanks} from "../../../services/bank.service";

const TrxPage = () => {
  const userData = useSelector(state => state.user.user);
  const [trxData, setTrxData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    tx_id: '',
    status: '',
    merchant_id: '',
    bank_id: '',
    from_date: moment().format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  });
  const [merchantData, setMerchantData] = useState([]);
  const [bankData, setBankData] = useState([]);

  const history = useHistory();
  const trxPath = '/transactions/export-trx';

  useEffect((searchParams = search) => {
    const fetchData = async () => {
      if (userData.role.label === 'ca') {
        history.push('/panel/dashboard-ca');
      }
      if (searchParams.from_date.length === 10 && searchParams.to_date.length === 10) {
        if (!searchParams.tx_id) {
          const result = await trxHistData(searchParams);
          sortingDataDesc(result, 'updated_at');
          setTrxData(result);
        }
      }

      if (searchParams.tx_id.length === 24) {
        const result = await trxHistData(searchParams);
        sortingDataDesc(result, 'created_at');
        setTrxData(result);
      }
    }
    fetchData();
    merchants();
    banks();
  }, [search, history, userData]);

  const trxHistData = async (searchParams) => {
    setLoading(true);

    try {
      let res = await fetchTrx(searchParams);
      setLoading(false);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      setLoading(false);
      console.log('err', e.response);
    }
  }

  const merchants = () => {
    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const merchantOptions = merchantData.map(value => {
    return { value: value.kode, label: value.name, name: 'merchant_code', key: value._id }
  });

  const merchantSelectComponent = () => (
    <Select isClearable options={merchantOptions} placeholder='select Merchant' id="merchant_id" name='merchant_code' onChange={handleChangeMerchant} required />
  )

  const handleChangeMerchant = async (event) => {
    const params = {...search}
    if (event) {
      params.merchant_id = event.value;
      setSearch(params);
    } else {
      params.merchant_id = '';
      setSearch(params);
    }
  }

  const banks = () => {
    fetchBanks().then(res => {
      setBankData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const bankOptions = bankData.map(value => {
    return { value: value.kode, label: value.name, name: 'bank_code', key: value._id }
  });

  const bankSelectComponent = () => (
    <Select isClearable options={bankOptions} placeholder='select Bank' id="bank_code" name='bank_code' onChange={handleChangeBank} required />
  )

  const handleChangeBank = async (event) => {
    const params = {...search}
    if (event) {
      params.bank_id = event.value;
      setSearch(params);
    } else {
      params.bank_id = '';
      setSearch(params);
    }
  }

  const selectStatus = () => {
    const options = status().map(val => {
      return {
        value: val.id,
        label: val.name
      }
    })

    return (
        <Select
            value={options.value}
            options={options}
            placeholder='select Status'
            onChange={handleChangeStatus}
        />
    )
  }

  // const sortingData = (items, key) => {
  //   items.sort((a, b) => {
  //     let valA;
  //     let valB;
  //     if (typeof a[key] === "string") {
  //       valA = a[key].toUpperCase(); // ignore upper and lowercase
  //       valB = b[key].toUpperCase(); // ignore upper and lowercase
  //     } else {
  //       valA = a[key]; // ignore upper and lowercase
  //       valB = b[key]; // ignore upper and lowercase
  //     }
  //
  //     if (valA < valB) {
  //       return -1;
  //     }
  //     if (valA > valB) {
  //       return 1;
  //     }
  //
  //     // if equals //
  //     return 0;
  //   });
  // }

  const sortingDataDesc = (items, key) => {
    items.sort((a, b) => {
      let valA;
      let valB;
      if (typeof a[key] === "string") {
        valA = a[key].toUpperCase(); // ignore upper and lowercase
        valB = b[key].toUpperCase(); // ignore upper and lowercase
      } else {
        valA = a[key]; // ignore upper and lowercase
        valB = b[key]; // ignore upper and lowercase
      }

      if (valA > valB) {
        return -1;
      }
      if (valA < valB) {
        return 1;
      }

      // if equals
      return 0;
    });
  }

  // const updateTrxHandler = (id) => {
  //   console.log('update');
  //   // history.push('/panel/merchants/edit/' + id);
  // }

  const handleChange = (event) => {
    const params = {...search}
    params[event.target.name] = event.target.value;
    setSearch(params);
  }

  const handleChangeStatus = (event) => {
    // console.log("status", event)
    const params = {...search}
    params.status = event.value;
    setSearch(params);
  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const loopTrx = () => {
    return trxData.map((val, i) => {

      if (val.status === '3') {
        val.status = 'inquiry';
      } else if (val.status === '0') {
        val.status = 'success';
      } else if (val.status === '1') {
        val.status = 'pending';
      } else if (val.status === '2') {
        val.status = 'failed';
      } else if (val.status === '4') {
        val.status = 'success with settlement';
      } else if (val.status === '5') {
        val.status = 'inquiry callback';
      }

      return (
          <tr key={i}>
            <td>{ i+1 }</td>
            <td>{ val.tx_id }</td>
            <td>{ val.ref_id }</td>
            <td>{ val.bill_id }</td>
            <td>{ val.merchant_id }</td>
            <td>{ val.bank_id }</td>
            <td>{ val.status }</td>
            <td className='text-right'>{ currencyFormat(val.amount) }</td>
            <td className='text-right'>{ currencyFormat(val.total_admin) }</td>
            <td className='text-right'>{ currencyFormat(val.total_amount) }</td>
            <td className='text-right'>{ currencyFormat(val.deducted_balance) }</td>
            <td className='text-right'>{ currencyFormat(val.fee_benefit) }</td>
            <td className='text-right'>{ currencyFormat(val.fee_kilat) }</td>
            <td>
              <span data-toggle="modal" data-target="#exampleModal" style={{cursor: "pointer", color: "blue"}} data-whatever={ val?.tx_detail }>
                see detail
              </span>
            </td>
            <td>{ val.category_name }</td>
            <td>{ val.product_name }</td>
            <td className='text-right'>{ currencyFormat(val.first_balance) }</td>
            <td className='text-right'>{ currencyFormat(val.last_balance) }</td>
            <td>{ val.updated_at }</td>
          </tr>
      );
    })
  }

  const exportButton = () => {
    // const bank = userData.bank_code | '';
    return (
        <form method="POST" action={`${API_DSN}${trxPath}`}>
          <input type="hidden" name='merchant_id' value='' />
          <input type="hidden" name='from_date' value={search.from_date} />
          <input type="hidden" name='to_date' value={search.to_date} />
          <input type="hidden" name='status' value={search.status} />
          <button type="submit" className="btn btn-primary float-right">Export Data</button>
        </form>
    );
  }

  return (
      <div className="row">
        <div className="col-6 mb-2">
          <input
              type="text"
              name="tx_id"
              className="form-control float-right p-1"
              placeholder="Search Tx Id"
              value={search.tx_id}
              onChange={handleChange}
          />
        </div>
        <div className="col-6 mb-2">
          {selectStatus()}
        </div>
        <div className="col-6 mb-2">
          {merchantSelectComponent()}
        </div>
        <div className="col-6 mb-2">
          {bankSelectComponent()}
        </div>
        <div className="col-6 mb-2">
          <input
              type="text"
              name="from_date"
              className="form-control float-right p-1"
              placeholder="From Date"
              value={search.from_date}
              onChange={handleChange}
          />
        </div>
        <div className="col-6 mb-2">
          <input
              type="text"
              name="to_date"
              className="form-control float-right p-1"
              placeholder="To Date"
              value={search.to_date}
              onChange={handleChange}
          />
        </div>
        <div className="col-12">
          {exportButton()}
        </div>
        <div className="col-12">
          <hr className='hr-user' />
        </div>
        <div className="col-12">
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>ID</th>
                <th>TxId</th>
                <th>Ref ID</th>
                <th>Bill ID</th>
                <th>Merchant</th>
                <th>Bank</th>
                <th>Status</th>
                <th className='text-right'>Amount</th>
                <th className='text-right'>Admin</th>
                <th className='text-right'>Total</th>
                <th className='text-right'>Deducted Bal</th>
                <th className='text-right'>Fee Benefit</th>
                <th className='text-right'>Fee Kilat</th>
                <th>Trx detail</th>
                <th>Category</th>
                <th>Product</th>
                <th className='text-right'>First Balance</th>
                <th className='text-right'>Last Balance</th>
                <th>Created Date</th>
              </tr>
              </thead>
              <tbody>
              { loading && <tr><td colSpan='6'>Load Data</td></tr> }
              { (!loading && trxData.length > 0) && loopTrx() }
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                ...
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Transaction History",
  location: '/panel/trx'
}

export default withHeader(pageSet)(TrxPage)
