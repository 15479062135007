import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {deleteProductById, seacrchProductByParam} from "../../../services/products.service";
import {useSelector} from "react-redux";
import {fetchCategory} from "../../../services/category.service";
import {API_DSN} from "../../../services/config";
import Select from "react-select";
import {
  fetchMerchantProductByMerchantCode,
  removeMechantProductFromMerchant,
  storeMerchantProduct
} from "../../../services/merchant-product.service";

const ExportButton = () => {
  const trxPath = '/products/export-excel';
  return (
    <form method="GET" action={`${API_DSN}${trxPath}`}>
      <button type="submit" className="btn btn-success btn-block float-right">Export</button>
    </form>
  );
}

const ProductPage = () => {
  const userData = useSelector(state => state.user.user);
  const [productData, setProductData] = useState([]);
  const [productDataTmp, setProductDataTmp] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [merchantProdData, setMerchantProdData] = useState([]);
  const [page, setPage] = useState(1);
  const [cat, setCat] = useState({});
  const [brd, setBrd] = useState({});
  const [totalPage, setTotalPage] = useState(20);
  const brandsList = [
    { value: 'TELKOMSEL', label: 'TELKOMSEL', name: 'Telkomsel', key: 'TELKOMSEL' },
    { value: 'INDOSAT', label: 'INDOSAT', name: 'Indosat', key: 'INDOSAT' },
    { value: 'XL', label: 'XL', name: 'XL', key: 'XL' },
    { value: 'THREE', label: 'THREE', name: 'Three', key: 'THREE' },
    { value: 'AXIS', label: 'AXIS', name: 'Axis', key: 'AXIS' },
    { value: 'By.U', label: 'By.U', name: 'By.U', key: 'By.U' },
    { value: 'SMARTFREN', label: 'SMARTFREN', name: 'Smartfren', key: 'SMARTFREN' },
  ];

  const [timeOutTmp, setTimeOutTmp] = useState(0);

  const history = useHistory();

  useEffect(() => {


    if (userData.role.label === 'admin') {
      setIsAdmin(true);
    }
    productsData();
    categoriesData();
    merchantProducts(userData.bank_code);

  }, [history, userData, isAdmin]);

  const productsData = () => {
    setLoading(true);
    seacrchProductByParam({}).then(res => {
      setProductData(res.data.data);
      setProductDataTmp(res.data.data);
      setTotalPage(res.data.count);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally(() => {
      setLoading(false);
    });
  }
  const merchantProducts = (bank_code) => {

    fetchMerchantProductByMerchantCode(bank_code).then(res => {
      setMerchantProdData(res.data.data);

    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally(() => {
      setLoading(false);
    });

  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const categoriesData = () => {

    fetchCategory().then(res => {
      setCategories(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const handleChange = (event) => {
    const val = event.target.value;
    setSearch(event.target.value)
    let searchRes = productDataTmp;

    if (!event.target.value) {
      // setProductData(productDataTmp);
      setPage(1);
      productsData()
      return;
    }

    clearTimeout(timeOutTmp)
    if (!event) return "";

    const filterTimeout = setTimeout(async () => {
      console.log('====>', val)

      searchRes = {};
      const params = {};
      if (Object.keys(brd).length > 0) {
        params.brand = brd.brand;
      } else if (Object.keys(cat).length > 0) {
        params.category_id = cat.category_id;
      }

      searchRes = await seacrchProductByParam(params, 1, val);
      setTotalPage(searchRes.data.count);

      const searchRes2 = searchRes.data.data.filter(valFilter => {
        let cat_name = '-';
        categories.forEach(val2 => {
          if (valFilter.category_id === val2.code) {
            cat_name = val2.name;
          }
        });

        return cat_name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          valFilter.name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          valFilter.kode.toLowerCase().indexOf(val.toLowerCase()) > -1
      });


      setProductData(searchRes2);

    }, 1000);

    setTimeOutTmp(filterTimeout);
  }

  const handleChangeCategory = async (event, currentPage = 1) => {
    let searchProd2 = {

    };
    if (event === null) {
      setCat({});
      if (Object.keys(brd).length > 0) {
        searchProd2 = await seacrchProductByParam(brd, currentPage, search);
      } else {
        searchProd2 = await seacrchProductByParam({}, 1, search);
      }
    } else {
      console.log('event next :>> ', event);
      const category_id = event.value;
      setCat({ category_id });

      if (Object.keys(brd).length > 0) {
        const brand = brd.brand;
        searchProd2 = await seacrchProductByParam({ brand, category_id   }, currentPage, search);
      } else {
        searchProd2 = await seacrchProductByParam({ category_id }, currentPage, search);
      }
    }
    console.log('s :>> ', searchProd2);

    setProductData(searchProd2.data.data);
    setProductDataTmp(searchProd2.data.data);
    setTotalPage(searchProd2.data.count)
  }

  const handleChangeBrand = async (event, currentPage = 1) => {
    let searchProd2 = {};

    if (event === null) {
      setBrd({});
      if (Object.keys(cat).length > 0) {
        searchProd2 = await seacrchProductByParam(cat, currentPage);
      } else {
        searchProd2 = await seacrchProductByParam({}, currentPage, search);
      }
    } else {
      const brand = event.value;
      setBrd({ brand })
      if (Object.keys(cat).length > 0) {
        const category_id = cat.category_id;
        searchProd2 = await seacrchProductByParam({ brand, category_id }, currentPage, search);
      } else {
        searchProd2 = await seacrchProductByParam({ brand }, currentPage, search);
      }
    }
    console.log('s :>> ', searchProd2);

    setProductData(searchProd2.data.data);
    setProductDataTmp(searchProd2.data.data);
    setTotalPage(searchProd2.data.count)
  }

  const handleChangePage = async (currentPage = 1) => {
    let searchProd2 = {};
    setPage(currentPage);
    if (!cat.category_id && !brd.brand) {
      searchProd2 = await seacrchProductByParam({}, currentPage, search);
    } else {
      const params = {};
      if (cat.category_id) {
        params.category_id = cat.category_id;
      }
      if (brd.brand) {
        params.brand = brd.brand;
      }
      searchProd2 = await seacrchProductByParam(params, currentPage, search);
    }
    console.log('s :>> ', searchProd2);

    setProductData(searchProd2.data.data);
    setProductDataTmp(searchProd2.data.data);
  }

  const deleteHandler = (id) => {
    // console.log(id);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to delete this data?");
    if (!r) {
      return;
    }
    deleteProductById(id, {}).then(res => {
      console.log(res);
      alert('delete data success');
      productsData();
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    }).finally(() => {
      setLoading(false);
    });
  }

  const createProductHandler = () => {
    console.log('new');
    history.push('/panel/products/new');
  }

  const updateProductHandler = (id) => {
    console.log('new');
    history.push('/panel/products/edit/' + id);
  }

  const addToMerchantProduct = async (val) => {
    console.log('val :>> ', val);
    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to add Product this data?");
    if (!r) {
      return;
    }
    let reqParams = {};
    if (val.category_id === 'PULSE001' || val.category_id === 'PAKETDATA001' || val.category_id === 'GAME001' || val.category_id === 'EWALLET001' || val.category_id === 'PLNPRE001') {
      reqParams.fee_merchant = 50;
      reqParams.percent_price = val.price;
    } else {
      reqParams.fee_merchant = getFeeKilat(val.category_id);
      reqParams.percent_price = val.price;
    }

    reqParams.fee_kilat = 0;
    reqParams.fee_biller = 0;
    reqParams.bank_code = val.bank_code;
    reqParams.product_code = val.kode;
    reqParams.merchant_code = userData.bank_code;

    console.log('reqParams :>> ', reqParams);
    let result = await storeMerchantProduct(reqParams);
    if (result.status === 201 && result.data.message === 'created') {
      alert('Success add Merchant Product');
      merchantProducts(userData.bank_code);
      loopProduct();

    }


  }

  const removeFromMerchantProduct = async (val) => {

    // eslint-disable-next-line no-restricted-globals
    const r = confirm("are you sure to remove Product?");
    if (!r) {
      return;
    }

    var reqParams = {};
    reqParams.product_code = val.kode;
    reqParams.merchant_code = userData.bank_code;
    let result = await removeMechantProductFromMerchant(reqParams);
    console.log('result :>> ', result);
    if (result.status === 201 && result.data.message === 'removed') {
      alert('Remove Merchant Product');
      merchantProducts(userData.bank_code);
      loopProduct();

    }


  }
  const handleChecking = (val) => {
    console.log('val :>> ', val);
    if (val.checked) {
      removeFromMerchantProduct(val);
    } else {
      addToMerchantProduct(val);
    }

  }
  const CategoriesOptions = categories.map(value => {
    return { value: value.code, label: value.name, name: 'category_id', key: value._id }
  })
  const CategoriesSelectComponent = () => (
    <Select isClearable options={CategoriesOptions} placeholder='select Category' id="category_id" name='category_id' onChange={handleChangeCategory} required />
  )

  const brandsOptions = brandsList.map(value => {
    return { value: value.value, label: value.label, name: 'brand', key: value.value }
  })
  const brandsSelectComponent = () => (
      <Select isClearable options={brandsOptions} placeholder='select Brand' id="brand" name='brand' onChange={handleChangeBrand} required />
  )

  const checkStatusActive = (val) => {
    if (val === 0) {
      return 'Closed';
    } else if (val === 1) {
      return 'Aktif';

    } else if (val === 2) {
      return 'Product Gangguan';
    } else {
      return 'Unknown';
    }



  }

  const getFeeKilat = (val) => {
    //
    let fee = 0;
    if (val === 'PDAM001') {

      fee = Number(process.env.REACT_APP_FEE_KILAT_PDAM);
    } else if (val === 'BPJS001') {
      fee = Number(Number(process.env.FEE_KILAT_PDAMREACT_APP_));
    } else if (val === 'PLN001') {
      fee = Number(process.env.REACT_APP_FEE_KILAT_PLN);
    } else if (val === 'PBB001') {
      fee = Number(process.env.REACT_APP_FEE_KILAT_PBB);
    } else if (val === 'PULSE001' || val === 'PAKETDATA001' || val === 'GAME001' || val === 'EWALLET001' || val === 'PLNPRE001') {
      fee = Number(process.env.REACT_APP_FEE_KILAT_PULSA);
    } else {
      fee = 0;
    }

    return fee;
  }
  
  const loopProduct = () => {
    return productData.map((val, i) => {
      let cat_name = '-';
      categories.forEach(val2 => {
        if (val.category_id === val2.code) {
          cat_name = val2.name;
        }
      });
      val.checked = false;
      val.curr_price = val.price;
      if (page === 1) {
        val.no = i + 1;
      } else {
        const limit = 20 * (page - 1);
        val.no = i + 1 + limit;
      }
      // console.log('val.code1', val.kode)
      if (!isAdmin) {
        let checkIsProd = false;
        merchantProdData.forEach(mp => {
          // checkIsProd = false;
          if (!checkIsProd) {
            if (val.kode === mp.product_code) {
              checkIsProd = true;
              val.checked = true;
              val.curr_price = mp.percent_price + mp.fee_merchant + mp.fee_kilat;
              if (val.category_id === 'PDAM001') {
                val.curr_price = mp.fee_merchant;
              }
              // if (mp.product_code === 'PDKBKD') {
              //   console.log('val.code2', val.kode)
              //   console.log(val.curr_price)
              //   console.log(checkIsProd)
              // }
            } else {
              val.curr_price = val.price + Number(getFeeKilat(val.category_id));
            }
          }
        });
      }
      let active = checkStatusActive(val.active);

      

      return (
        <tr key={cat_name + '_' + i}   className={val.active !== 1 ? 'bg-danger' : '' } >
          <td>{String(val.no)}</td>
          <td>{cat_name}</td>
          <td>{val.kode}</td>
          <td>{val.name.length >= 30 ? val.name.substr(0, 30) + '...' : val.name}</td>
          <td>{currencyFormat(val.curr_price)}</td>
          <td>{currencyFormat(val?.admin_fee || 0)}</td>
          {isAdmin && <td>{val.biller_product}</td>}
          {isAdmin && <td>{val.bank_code}</td>}
          <td>{active}</td>

          {!isAdmin &&
            <td align='center'>
              <input checked={val.checked} type="checkbox" value="" id="flexCheckChecked" onChange={() => handleChecking(val)} />
            </td>
          }
          {isAdmin &&

            <td>
              <span className="badge bg-danger mr-1" onClick={() => { deleteHandler(val._id) }}>
                <i className="fas fa-trash-alt p-1" aria-hidden="true" />
              </span>

              <span className="badge bg-success mr-1" onClick={() => { updateProductHandler(val._id) }}>
                <i className="fas fa-pencil-alt p-1" aria-hidden="true" />
              </span>
            </td>}
        </tr>
      );
    })
  }

  const loopPagination = () => {
    const pagination = Math.ceil(totalPage / 20);
    const arr = [];
    for (let i = 0; i < pagination; i++) {
      arr.push(i);
    }
    return arr.map((val, i) => {
      return (
          <li className="page-item" key={'pagination_' + i}>
            <span className="page-link" onClick={() => handleChangePage(i + 1)}>{ i + 1 }</span>
          </li>
      );
    });
  }

  return (
    <div className="row">
      <div className="col-sm-4 mb-3">
        {CategoriesSelectComponent()}
      </div>
      <div className="col-sm-4 mb-3">
        {brandsSelectComponent()}
      </div>
      <div className="col-sm-4 mb-3">
        <input
          type="text"
          name="table_search"
          className="form-control float-right p-1"
          onChange={handleChange}
          onKeyDown={CategoriesSelectComponent}
          value={search}
          placeholder="Search" />
      </div>

      {isAdmin &&
        <div className="col-sm-2 mb-3">
          <button type="button" className="btn btn-block btn-primary" onClick={() => { createProductHandler() }}>
            <i className='fas fa-plus' aria-hidden='true' />
          </button>
        </div>
      }
      <div className="col-sm-2">
        <ExportButton />
      </div>
      <div className="col-12">
        <hr className='hr-user' />
      </div>
      <div className="col-12">
        <div className="card-body table-responsive p-0">
          <table className="table text-nowrap table-striped table-sm">
            <thead>
              <tr >
                <th>ID</th>
                <th>Cat Code</th>
                <th>Code</th>
                <th>Name</th>
                <th>Price</th>
                <th>Admin Fee</th>
                {isAdmin && <th>Biller Product</th>}
                {isAdmin && <th>Bank Code</th>}
                <th>Active</th>
                <th >Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && <tr><td colSpan='6'>Load Data</td></tr>}
              {(!loading && productData.length > 0) && loopProduct()}
            </tbody>
          </table>

          <nav aria-label="Page navigation example" >
            <ul className="pagination">
              <li className="page-item">
                <span className="page-link" onClick={() => page === 1 ? '' : handleChangePage(page - 1)}>Previous</span>
              </li>
              {(!loading && productData.length > 0) && loopPagination()}
              <li className="page-item">
                <span className="page-link" onClick={() => handleChangePage(page + 1)}>Next</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

let pageSet = {
  title: "Product",
  location: '/panel/products'
}

export default withHeader(pageSet)(ProductPage)
